.bx--side-nav__navigation {
    background-color: #12171A !important;
}

.bx--side-nav__link,  .bx--side-nav__submenu {
    color: #c6c6c6 !important;
}

.bx--side-nav hr {
    border-bottom: #c6c6c6 !important;
}

.bx--side-nav svg {
    fill: white !important;
}

.bx--side-nav__link-text, .bx--side-nav__submenu {
    color: white !important;
}

.bx--side-nav__link:hover, .bx--side-nav__submenu:hover {
    background-color: #373D43 !important;
}

.bx--side-nav__link.active {
    background-color: #636363 !important;
}

.bx--side-nav__link.active:before {
    background-color: #4A90E2 !important;
}