.video-title {
    font-weight: bold;
}

.video-title {
    font-weight: bold;
}

.video-link a {
    margin-left: 15px;
    font-weight: bold;
}

.video-entry {
    padding-bottom: 16px;
}

div.videos-container li:not(:first-child) {
    padding-top: 20px;
}

div.video-holder {
    display: inline-block;
    position: relative;
    padding-bottom: 48%;
    width: 80%;
}

div.video-holder iframe {
    height: 80% !important;
    position: absolute;
    top: 0; left: 0;
    width: 80% !important;
}

.video-constraint {
    width: 70%;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width:38rem) {
    .video-constraint {
        width: 100%;
    }
}