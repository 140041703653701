div.news-coverage-container li:not(:first-child) {
    padding-top: 20px;
}

div.news-coverage-container a {
    text-decoration: none;
}

div.news-coverage-container a :hover {
    text-decoration: underline;
}

div.blank-space {
    height: 100vh;
}