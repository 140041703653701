div.related-work-container li:not(:first-child) {
    padding-top: 20px;
}

div.related-work-container a {
    text-decoration: none;
}

div.related-work-container a :hover {
    text-decoration: underline;
}

div.blank-space {
    height: 100vh;
}