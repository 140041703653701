.fs-hmda-container {
    padding-bottom: 98px !important;
    padding-left: 40px !important;
    padding-right: 45px !important;
    background-color: white;
    max-width: 1200px !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.fs-hmda-container img {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media (max-width: 44rem) {
    .fs-hmda-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.fs-hmda-title {
    padding-top: 100px;
    padding-bottom: 60px;
}

.fs-hmda-stamp {
    width: 130px;
    height: 50px;
    line-height: 50px;
    background: #e9e9e9;
    color: #3C4646;
    text-align: center;
    vertical-align: middle;
    float: left;
}

.fs-hmda-content {
    margin-top: 20px;
}

.fs-hmda-table {
    line-height: 180%;
    width: 100%;
}

.fs-hmda-table th {
    vertical-align: middle !important;
}

.fs-hmda-table tr:not(:last-child) {
    border-bottom: 1px solid #aaa;
}

.fs-hmda-table td {
    padding-left: 10px;
}

.fs-hmda-table th {
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #aaa;
    border-top: 1px solid #aaa;
    padding-left: 10px;
}

.fs-hmda-container .fact {
    width: 30%;
    max-width: 260px;
    // border-right: 1px solid #aaa;
}

.fs-hmda-table td.value {
    padding-top: 8px;
    padding-bottom: 8px;
}

.fs-hmda-table td.fact {
    font-weight: bold;
}

.fs-hmda-table tr.top-most-row {
    border-top: 1px solid #aaa;
}

.fs-hmda-stamp .stamp {
    font-weight: bold;
}

.fs-hmda-container .bx--row {
    margin-left: 11px;
    margin-right: 20px;
}

// .fs-hmda-container .model-title {
//     text-transform: uppercase;
// }

.fs-hmda-container p, .fs-hmda-container .div-as-paragraph {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
}

.fs-hmda-container ul, .fs-hmda-container ol
{
    padding-left: 1.5rem !important;
}

.fs-hmda-container ul > li
{
    list-style-type: initial !important;
}

.fs-hmda-container ol > li
{
    list-style-type: decimal !important;
}

.fs-hmda-container .bx--data-table th, .fs-hmda-container .bx--data-table td {
    font-size: 14px;
    line-height: 16px;
}


.fs-hmda-container pre {
    margin-top: 10px;
    background: #eee;
    border-radius: 20px;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.fs-hmda-container .model-title {
    margin-top: 50px;
}

.fs-hmda-table .bx--data-table > thead > tr > th {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.fs-hmda-table .bx--data-table > tbody > tr > td {
    border-bottom: 1px solid #ddd;
}

.fs-hmda-table .bx--data-table > thead > tr > th:first-child {
    width: 20%
}

.fs-hmda-table .bx--data-table > thead > tr > th:not(:first-child) {
    width: 20%
}

.fs-hmda-container .term-definition {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    cursor: help;
    padding-bottom: 7px;
    color: black;
}

.bx--tooltip__content {
    line-height: 18px !important;
}

.fs-hmda-container code {
    background-color:#EEEEEE;
}

.fs-hmda-table thead > tr > td {
    font-size: 16px;
    padding: 10px;
    background: #F4F4F4;
}