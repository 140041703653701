div.governance-images {
    padding-top: 30px;
}

div.figure-caption {
    margin-top: 16px;
}

div.governance-images > .bx--row > div {
    padding-bottom: 57px;
}

div.governance-images > .bx--row > div.no-bottom-padding {
    padding-bottom: 0px;
}

div.governance-images > .bx--row > .bx--col-md-4 {
    padding-left: 0px !important;
}

div.governance-figures svg {
    max-width: 100%;
}

div.governance-figures img {
    width: 100%;
}

div.governance-figures.roles {
    max-width: 466px;
    max-height: 364px;
    margin-top: 20px;
}

div.governance-figures.facts {
    max-width: 699px;
    max-height: 380px;
    margin-top: 20px;
    margin-bottom: 20px;
}

div.governance-figures.views {
    max-width: 587px;
    max-height: 387px;
    margin-top: 20px;
    margin-bottom: 20px;
}

sup.governance-asterisk {
    font-size: 12px;
    line-height: 12px;
}

.governance-table th {
    vertical-align: middle !important;
}

.governance-table td {
    background-color: white !important;
}

.governance-table-container {
    width: 100%;
}

.governance-table-container > div:not(:first-child) {
    padding-top: 0px;
}

td.columns-3.col-0 {
    width: 50%;
}

td.columns-3.col-1,
td.columns-3.col-2 {
    width: 25%;
}

td.columns-4.col-0 {
    width: 40%;
}

td.columns-4.col-1,
td.columns-4.col-2,
td.columns-4.col-3 {
    width: 20%;
}

td.mobile-cell.col-0 {
    width: 60%
}

.mobile-cell-values {
    text-align: right;
}

.governance-table.mobile {
    display: none;
}

@media (max-width: 500px) {
    .governance-table.desktop {
        display: none;
    }

    .governance-table.mobile {
        display: block;
    }
}