span.question {
    font-weight: bold;
}

ol.faq-list {
    font-size: 16px;
}

ol.faq-list > li {
    padding-left: 10px;
}

sup.faq-asterisk {
    font-size: 12px;
    line-height: 12px;
}