body {
    color: #3C4646 !important;
}

.bx--content {
    padding: 0px !important ;
    margin: 0px !important ;
    margin-top: 48px !important;
    margin-left: 256px !important;
    min-height: 350px;
}

@media (max-width: 66rem) {
    .bx--content {
        margin-left: 0px !important;
    }
}

.bx--grid {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.bx--row {
    margin: 0px !important;
}

.logo-title {
    font-size: 36px;
    line-height: 36px;
}

#under-construction {
    padding-top: 100px;
}

.link-with-icon {
    color: #8DC2FC !important;
}

.link-with-icon svg {
    fill: #8DC2FC !important;
    margin-left: 10px;
}

a.link-with-icon {
    display: inline-flex;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

div.div-as-paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0;
}

.bx--data-table th {
    vertical-align: middle !important;
    text-align: left !important;
}
