ol.work-in-progress-list > li {
    padding-left: 10px;
}

.intro-img-container {
    max-width: 250px;
    position: relative;
    float: left;
    margin-right: 40px;
    width: 100%;
}

.intro-img-text {
    padding-bottom: 10px;
}

.intro-img-image img{
    width: 100%;
}

div.intro-images {
    padding-top: 30px;
}

div.intro-images > .bx--row > .bottom-padded {
    padding-bottom: 57px;
}

div.intro-images > .bx--row > .bx--col-md-4 {
    padding-left: 0px !important;
}

.intro-list > li {
    padding-left: 10px;
}

.intro-list > li:not(:first-child) {
    padding-top: 5px;
}

.intro-term-definition {
    font-size: 16px !important;
    line-height: 20px !important;
    color: #3c4646 !important;
}

.intro-term-definition + .bx--assistive-text {
    max-width: 200px;
}