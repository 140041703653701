.fs-datasheet-container {
    padding-bottom: 98px !important;
    padding-left: 40px !important;
    padding-right: 45px !important;
    background-color: white;
    max-width: 900px !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin: 0px !important;
}

.fs-datasheet-container img {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media (max-width: 44rem) {
    .fs-datasheet-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.fs-datasheet-title {
    padding-top: 100px;
    padding-bottom: 60px;
}

.fs-datasheet-stamp {
    width: 130px;
    height: 50px;
    line-height: 50px;
    background: #e9e9e9;
    color: #3C4646;
    text-align: center;
    vertical-align: middle;
    float: left;
}

.fs-datasheet-table {
    margin-top: 40px;
    line-height: 180%;
}

.fs-datasheet-table td {
    border-bottom: 1px solid #aaa;
    padding-left: 10px;
}

.fs-datasheet-table th {
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #aaa;
    border-top: 1px solid #aaa;
    padding-left: 10px;
}

.fs-datasheet-container .fact {
    width: 20%;
    // border-right: 1px solid #aaa;
}

.fs-datasheet-table td.value {
    padding-top: 8px;
    padding-bottom: 8px;
}

.fs-datasheet-table td.fact {
    font-weight: bold;
}

.fs-datasheet-table tr.top-most-row {
    border-top: 1px solid #aaa;
}

.fs-datasheet-stamp .stamp {
    font-weight: bold;
}

.fs-datasheet-container .bx--row {
    margin-left: 11px;
    margin-right: 20px;
}

.fs-datasheet-container .model-title {
    text-transform: capitalize;
}

.fs-datasheet-container p, .fs-datasheet-container .div-as-paragraph {
    font-size: 14px;
    line-height: 16px;
}

.fs-datasheet-container ul, .fs-datasheet-container ol
{
    padding-left: 1rem !important;
}

.fs-datasheet-container ul > li
{
    list-style-type: initial !important;
}

.fs-datasheet-container ol > li
{
    list-style-type: decimal !important;
}

.fs-datasheet-container .bx--data-table th, .fs-datasheet-container .bx--data-table td {
    font-size: 14px;
    line-height: 16px;
}

.fs-datasheet-container th {
    vertical-align: middle !important;
}
