.example-title-wrapper {
    width: 100%;
    margin-left: 74px;
    margin-top: 40px;
    margin-bottom: 15px;
    margin-right: 20px;
}

.example-content-wrapper {
    margin-left: 74px;
    min-height: 1200px;
    margin-right: 20px;
    width: 100%;
}

#presentation-combo {
    width: 224px;
}

.example-content-wrapper .factsheet {
    margin-top: 33px;
}

.logo-title svg, .example-content-wrapper-title svg {
    fill: #3c4646 !important;
}

.tooltip-explain {
    font-size: 16px;
    line-height: 22px;
    margin: 18px;
}

.presentation-chooser-group {
    margin-top: 23px;
}

.presentation-rectangular {
    background: white !important;
    width: 272px;
    height: 95px;
    float: left;
    margin-right: 20px;
    margin-top: 10px !important;
}

.presentation-full-tile {
    display: flex;
    justify-content: flex-end;
    height: inherit;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    width: 225px;
}

.presentation-rectangular:hover {
    background: #e7e7e7 !important;
}

.presentation-tile-image {
    padding-right: 15px;
}

.presentation-tile-text {
    float:right;
    padding-bottom: 5px;
    width: 179px;
}

.presentation-tile-text-title {
    font-weight: bold;
}

.example-title-wrapper div.logo-subtitle {
    font-size: 18px;
    line-height: 22px;
    float: left;
    padding-bottom: 20px;
    padding-top: 15px;
}

.example-title-wrapper div.tags {
    float: right;
    padding-top: 7px;
}

.presentation-tile-text-description {
    padding-top: 5px;
}

div.example-title-wrapper > .tags > button > .bx--assistive-text {
    width: 150px;
}

.example-top {
    padding-top: 23px;
}

@media (max-width: 66rem) {
    .example-title-wrapper {
        margin-left: 11px;
    }

    .example-content-wrapper {
        margin-left: 11px;
    }
}

@media (max-width: 40rem) {

    .example-title-wrapper div.tags {
        float: none;
        margin-top: 10px;
    }

    .example-title-wrapper div.logo-title {
        float: none;
    }
}

@media print {
    header {
        visibility: hidden;
    }
}