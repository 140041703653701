div.factsheet-card {
    background-color: #FFF;
    float: left;
    margin: 10px 20px 10px 0px;
    min-height: 266px;
    position: relative;
    width: 264px;
}

div.factsheet-card-header {
    color: #FFF;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0px;
    padding: 80px 10px 10px 15px;
    width: 100%;
}

div.factsheet-card-text {
    font-size: 20px;
    color: #3c4646;
    line-height: 26px;
    padding: 20px 20px 15px;
    width: 200px;
}

div.factsheet-card:hover {
    background: lightgray;
}

div.factsheet-card.grey {
    background: #E7E7E7;
}

div.factsheet-card.grey:hover {
    background: gray;
}

div.factsheet-card-link {
    bottom: 0px;
    right: 0px;
    padding: 0px 20px 20px 0px;
    position: absolute;
}

div.factsheet-card-link svg {
    fill: #1972fd;
}

div.factsheet-card-icon {
    bottom: 0px;
    padding: 0px 0px 20px 15px;
    position: absolute;
}