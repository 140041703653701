div.example-card {
    background-color: #FFF;
    float: left;
    margin: 10px 20px 10px 0px;
    height: 266px;
    position: relative;
    width: 264px;
    border: 1px solid #c2c2c2;
}

div.example-card-header {
    background-color: #c7c7c7;
    height: 81px;
    width: 100%;
}

div.example-card-text {
    padding-top: 15px;
    padding-left: 21px;
    padding-bottom: 15px;
    padding-right: 21px;
}

div.example-card-description {
    font-size: 14px;
    line-height: 19px;
    color: #3c4646;
    padding-top: 24px;
}

div.example-card-title {
    font-size: 20px;
    line-height: 24px;
    color: #282828
}

div.example-card:hover {
    background: #E7E7E7;
}

div.example-card-link {
    bottom: 0px;
    right: 0px;
    position: absolute;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
}

div.example-card-tags {
    bottom: 0px;
    position: absolute;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
}

div.example-card-text .example-card-description {
    font-size: 14px;
    font-weight: normal;
    color: #282828
}

div.example-card svg {
    fill: #1972fd;
}

div.examples-container {
    width: 860px;
    overflow: auto;
}

section.example-box-section {
    padding-top: 4em;
}

.tag-key-table td,
.tag-key-table th {
    vertical-align: middle !important;
}

.tag-key-table td {
    background: white !important;
}

.tag-key-table th {
    height: 2rem !important;
}

td.tag {
    min-width: 110px;
    padding-left: 10px !important;
    padding-right: 0 !important;
}

@media only screen and (max-width: 860px) {
    div.examples-container {
        width: 100%;
    }
}

@media only screen and (min-width: 1056px) and (max-width: 1165px) {
    div.examples-container {
        width: 100%;
    }
}