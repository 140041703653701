.methodology-step:not(:first-child) {
    padding-top: 40px;
}

.methodology-step-title {
    vertical-align: middle;
    line-height: 52px;
    padding-bottom: 18px;
}

.methodology-step-title svg {
    float: left;
}

.methodology-step-title-text {
    font-size: 18px;
    font-weight: bold;
    padding-left: 15px;
}

.methodology-table th {
    vertical-align: middle !important;
    /* font-size: 16px; */
}

.methodology-table td {
    background-color: white !important;
    /* font-size: 16px; */
}