$slide-width: 770px;
$slide-height: 430px;
$slide-font-bix: 36px;
$slide-font-small: 14px;

.fs-slideshow-container {
    margin: 0px !important;
    width: $slide-width;
}

.fs-slideshow-container img {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.fs-slideshow-container .slide {
    width: $slide-width;
    height: $slide-height;
    margin-left: auto !important;
    margin-right: auto !important;
}

.fs-slideshow-container .thanks {
    text-align: center;
    vertical-align: middle;
    line-height: $slide-height;
    font-size: 36px;
    background-color: black;
    color: white;
}

.fs-slideshow-container .empty {
    line-height: $slide-height;
}

.fs-slideshow-container .title {
    font-size: 36px;
    line-height: 40px;
    text-transform: capitalize;
    vertical-align: middle;
}

.fs-slideshow-container .box {
    width: 50%;
    height: 100%;
    float: left;
    padding: 30px;
}

.fs-slideshow-container .black {
    background-color: black;
    color:white;
}

.fs-slideshow-container .grey {
    background-color: #5596E6;
    color:white;
}

.fs-slideshow-container .white {
    background-color: white;
    color: #3C4646;
}

.white p, .white .div-as-paragraph {
    font-size: 20px !important;
    line-height: 24px !important;
    padding-bottom: 15px !important;
}

.white .bx--data-table th, .white .bx--data-table td {
    font-size: 18px;
    line-height: 22px;
}

.white pre {
    font-size: 12px !important;
    line-height: 14px !important;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.fact .box.grey {
    font-size: 36px;
}

.react-multi-carousel-dot-list {
    position: relative !important;
    margin-top: 10px !important;
}

.box.white ul, .box.white ol
{
    padding-left: 1rem !important;
}

.box.white ul {
    list-style: none; /* Remove default bullets */
}

.box.white ul li::before {
    content: "-";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    padding-top: 10px;
}

// .box.white ul > li
// {
//     list-style-type: initial !important;
// }

.box.white ol > li
{
    list-style-type: decimal !important;
}

@media (max-width: 66rem) {
    $slide-width-sm: 620px;
    $slide-height-sm: 340px;

    .fs-slideshow-container {
        width: $slide-width-sm;
    }

    .fs-slideshow-container .slide {
        width: $slide-width-sm;
        height: $slide-height-sm;
    }

    .fs-slideshow-container .thanks {
        line-height: $slide-height-sm;
        font-size: 36px;
    }

    .fs-slideshow-container .empty {
        line-height: $slide-height-sm;
    }
}