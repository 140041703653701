.event-title {
    font-weight: bold;
}

.event-link a {
    margin-left: 15px;
    font-weight: bold;
}

.event-entry {
    padding-bottom: 16px;
}