.footer a {
    color: #8DC2FC !important;
}

.footer-wrapper {
    padding-top: 100px;
    padding-left: 40px !important;
    padding-bottom: 140px;
    max-width: 460px;
}

.footer-text {
    font-size: 14px;
    line-height: 18px;
}

.footer-tile {
    padding-right: 20px;
}

.footer {
    background-color: #4A4A4A;
    color: white;
}

@media (max-width: 66rem)  {
    .footer-wrapper {
        padding-left: 10px !important;
    }
}
